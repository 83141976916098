import { createRouter, createWebHistory } from 'vue-router'
import { uzr } from '@/user/User';
import { hyzuzr } from '@/user/Hyzusr';

let log_customer = uzr.currentUser()
var cust_perm = {}

if (log_customer) {
    cust_perm = log_customer.perm
    cust_perm
}

let log_hyz_admin = hyzuzr.currentHyzUser()
var hyz_perm = {}

if (log_hyz_admin) {
    hyz_perm = log_hyz_admin.perm
}
// alert(JSON.stringify(hyz_perm))
// alert(hyz_perm.c_v_h_role)
const routes = [
    {
        path: '/',
        redirect: 'home',
        component: () => import('@/layouts/CustomerLayout.vue'),

        children: [

            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/storefront/HomePage.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/auth/LoginPage.vue'),
                meta: { has_perm: true, },
            },


            {
                path: 'products-near-you/:productslug?',
                name: 'products-near-you',
                component: () => import('@/views/storefront/ProductsPage.vue'),
                meta: { has_perm: true, },
            },

            // {
            //     path: 'p/:productslug?',
            //     name: 'productdetails',
            //     component: () => import('@/views/product/ProductDetails.vue'),
            //     meta: { has_perm: true, },
            // },

            {
                path: 'services-near-you/:serviceslug',
                name: 'services-near-you',
                component: () => import('@/views/storefront/ServicesPage.vue'),
                meta: { has_perm: true, },
            },

            // {
            //     path: 's/:serviceslug?',
            //     name: 'servicedetails',
            //     component: () => import('@/views/services/ServiceDetails.vue'),
            //     meta: { has_perm: true, },
            // },

            {
                path: 'businesses/:categoryslug?',
                name: 'businesses',
                component: () => import('@/views/storefront/BusinessesPage.vue'),
                meta: { has_perm: true, },
            },
            // Not using below rout
            {
                path: 'business-store-details/:businessslug',
                name: 'business-store-details',
                component: () => import('@/views/storefront/BusinessesStoreDetails.vue'),
                meta: { has_perm: true, },
            },
            // 
            // {
            //     path: 'bus-store-products/:businessslug',
            //     name: 'bus-store-products',
            //     component: () => import('@/views/storefront/OurProducts.vue'),
            //     meta: { has_perm: true, },
            // },
            // {
            //     path: 'bus-store-services/:businessslug',
            //     name: 'bus-store-services',
            //     component: () => import('@/views/storefront/OurServices.vue'),
            //     meta: { has_perm: true, },
            // },
            // {
            //     path: 'privacy-policy',
            //     name: 'privacy-policy',
            //     component: () => import('@/views/footerLinks/privacyPolicy.vue'),
            //     meta: { has_perm: true, },
            // },
            {
                path: 'deactivate-account',
                name: 'deactivate-account',
                component: () => import('@/views/footerLinks/deactivateAccount.vue'),
                meta: { has_perm: true, },
            },
            // {
            //     path: 'help-support',
            //     name: 'help-support',
            //     component: () => import('@/views/footerLinks/helpAndSupport.vue'),
            //     meta: { has_perm: true, },
            // },

        ]
    },
    // Business details layout --start
    {
        path: '/',
        redirect: 'home',
        component: () => import('@/layouts/businessDetailsLayout.vue'),

        children: [
            {
                path: '/:businessslug',
                name: 'business-store-dets',
                component: () => import('@/views/storefront/businessDets.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'p/:productslug?/:businessslug?',
                name: 'productdetails',
                component: () => import('@/views/product/ProductDetails.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 's/:serviceslug?/:businessslug?',
                name: 'servicedetails',
                component: () => import('@/views/services/ServiceDetails.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'bus-store-products/:businessslug',
                name: 'bus-store-products',
                component: () => import('@/views/storefront/OurProducts.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'bus-store-services/:businessslug',
                name: 'bus-store-services',
                component: () => import('@/views/storefront/OurServices.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'shop/account/cart/:businessslug',
                name: 'shop-cart',
                component: () => import('@/views/customer/MyCart.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'shop/account/place-order/:cartId?/:userId?/:businessslug?',
                name: 'shop-place-order',
                component: () => import('@/views/customer/placeOrder.vue'),
                meta: { has_perm: true, },
            },
        ]
    },
    // Business details layout --end

    {
        path: "/",
        component: () => import('@/layouts/CustomerLayout.vue'),
        meta: { requiresAuth: true, requiresAuthHyz: false },
        children: [
            {
                path: 'account/:pagetype',
                name: 'account',
                component: () => import('@/views/customer/MyProfile.vue'),
                meta: { has_perm: true, },
            },

            {
                path: 'account/cart',
                name: 'cart',
                component: () => import('@/views/customer/MyCart.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'account/place-order/:cartId?/:userId?',
                name: 'place-order',
                component: () => import('@/views/customer/placeOrder.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'become-a-partner',
                name: 'become-a-partner',
                component: () => import('@/views/partner/becomAPartner.vue'),
                meta: { has_perm: true, },
            },
        ]
    },

    /// with auth
    {
        path: '/business',
        component: () => import('@/layouts/BusinessLayout.vue'),
        meta: { requiresAuth: true, requiresAuthHyz: false },
        children: [

            {
                path: '',
                name: 'business-dashboard',
                component: () => import('@/views/business/businessDashboard.vue'),
                meta: { has_perm: true, },
            },

            {
                path: 'businessdetails',
                name: 'business-details',
                component: () => import('@/views/business/registration/businessDetails.vue'),
                meta: { has_perm: true, },
            },

            {
                path: 'congratulation/:bid',
                name: 'congratulationp',
                component: () => import('@/views/business/registration/congratulationPage.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'contactdetails/:bid',
                name: 'contactdetailsp',
                component: () => import('@/views/business/registration/addContactDetails.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businesstime/:bid',
                name: 'businesstime',
                component: () => import('@/views/business/registration/businessTiming.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businesscategs/:bid',
                name: 'business-categs',
                component: () => import('@/views/business/registration/businessCategories.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businesslocation/:bid',
                name: 'business-location',
                component: () => import('@/views/business/registration/addLocation.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessimages',
                name: 'business-images',
                component: () => import('@/views/business/data/businessImages.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessproducts',
                name: 'business-products',
                component: () => import('@/views/business/data/businessProducts.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessproduct-view/:prdctSlug',
                name: 'business-product-view',
                component: () => import('@/views/business/data/businessProductView.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businesscontacts',
                name: 'business-contacts',
                component: () => import('@/views/business/data/businessContacts.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businesstimes',
                name: 'business-times',
                component: () => import('@/views/business/data/businessTimes.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessroles/add',
                name: 'new-businessrole',
                component: () => import('@/views/business/manage/roles/roleAdd.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessroles',
                name: 'businessroles',
                component: () => import('@/views/business/manage/roles/roleList.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'businessroles/edit/:roleid',
                name: 'edit-business-role',
                component: () => import('@/views/business/manage/roles/roleEdit.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'businessservices',
                name: 'business-services',
                component: () => import('@/views/business/data/businessServices.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessservice-view/:serviceSlug',
                name: 'business-service-view',
                component: () => import('@/views/business/data/businessServiceView.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessvideos',
                name: 'business-videos',
                component: () => import('@/views/business/data/businessVideos.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'businessbrochure',
                name: 'business-brochure',
                component: () => import('@/views/business/data/businessBrochure.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'business-enquiry',
                name: 'business-enquiry',
                component: () => import('@/views/business/data/businessEnquiry.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'business-customers',
                name: 'business-customers',
                component: () => import('@/views/business/data/businessCustomers.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'business-orders',
                name: 'business-orders',
                component: () => import('@/views/business/data/businessOrders.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'business-order-details/:orderId',
                name: 'business-order-details',
                component: () => import('@/views/business/data/businessOrderDetails.vue'),
                meta: { has_perm: true, },
            },

        ],
    },
    /// with auth

    /// without auth
    {
        path: '/business',
        component: () => import('@/layouts/BusinessLayout.vue'),
        children: [

            {
                path: 'sellerregistration',
                name: 'seller-registration',
                beforeEnter: (to, from, next) => {
                    // Perform redirect without showing the contents of the component
                    const user = localStorage.getItem('nrsllrcust')
                    if (user) {
                        next({ name: 'business-details' });
                    } else {
                        next();
                    }

                },
                component: () => import('@/views/business/auth/registerBusiness.vue'),
                meta: { has_perm: true, },
            },
        ],
    },
    /// without auth

    {
        path: '/serviceprovider',
        component: () => import('@/layouts/providerLayout.vue'),
        meta: { requiresAuth: true, requiresAuthHyz: false },

        children: [

            {
                path: '',
                name: 'provider-dashboard',
                component: () => import('@/views/serviceProvider/ProviderDashboard.vue'),
                meta: { has_perm: true, },
            },

        ],
    },


    //HYZ with auth
    {
        path: '/fingertipypadm',
        component: () => import('@/layouts/HyzLayout.vue'),
        meta: { requiresAuthHyz: true, requiresAuth: false },
        children: [

            {
                path: '',
                name: 'hyz-dashboard',
                component: () => import('@/views/hyzadmin/AdminDashboard.vue'),
                meta: { has_perm: true, },
            },

            {
                path: 'hyzroles',
                name: 'hyzroles',
                component: () => import('@/views/hyzadmin/roles/roleList.vue'),
                meta: { has_perm: hyz_perm.c_v_h_role },
            },
            {
                path: 'hyzroles/add',
                name: 'new-hyzrole',
                component: () => import('@/views/hyzadmin/roles/roleAdd.vue'),
                meta: { has_perm: hyz_perm.c_a_h_role },
            },
            {
                path: 'hyzroles/edit/:roleid',
                name: 'edit-hyz-role',
                component: () => import('@/views/hyzadmin/roles/roleEdit.vue'),
                meta: { has_perm: hyz_perm.c_e_h_role },
            },
            {
                path: 'staffs',
                name: 'staffs',
                component: () => import('@/views/hyzadmin/staff/staffList.vue'),
                meta: { has_perm: hyz_perm.c_v_h_staff },
            },
            {
                path: 'staffs/add',
                name: 'new-staffs',
                component: () => import('@/views/hyzadmin/staff/staffAdd.vue'),
                meta: { has_perm: hyz_perm.c_a_h_staff },
            },
            {
                path: 'staffs/edit/:staffid',
                name: 'edit-staff',
                component: () => import('@/views/hyzadmin/staff/staffEdit.vue'),
                meta: { has_perm: hyz_perm.c_e_h_staff },
            },
            {
                path: 'staffs/resetpassword/:staffid',
                name: 'staff-reset-password',
                component: () => import('@/views/hyzadmin/staff/stafResetPassword.vue'),
                meta: { has_perm: hyz_perm.c_e_h_staff },
            },
            {
                path: 'staffs/view/:staffid',
                name: 'view-staff',
                component: () => import('@/views/hyzadmin/staff/viewStaff.vue'),
                meta: { has_perm: hyz_perm.c_v_h_staff },
            },
            {
                path: 'hyzadmprofile',
                name: 'hyz-adm-profile',
                component: () => import('@/views/hyzadmin/ViewProfile.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'adm-reset-password',
                name: 'adm-reset-password',
                component: () => import('@/views/hyzadmin/resetPassword.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'listcategories',
                name: 'hyz-categories-list',
                component: () => import('@/views/hyzadmin/masterdata/categoryList.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'category/add',
                name: 'new-category',
                component: () => import('@/views/hyzadmin/masterdata/categoryAdd.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'category/view/:categoryid',
                name: 'view-category',
                component: () => import('@/views/hyzadmin/masterdata/viewCategory.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'category/edit/:categoryid',
                name: 'edit-category',
                component: () => import('@/views/hyzadmin/masterdata/categoryEdit.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'listbusinesstype',
                name: 'businesstype-list',
                component: () => import('@/views/hyzadmin/masterdata/businesstypeList.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'businesstype/add',
                name: 'new-businesstype',
                component: () => import('@/views/hyzadmin/masterdata/businesstypeAdd.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
                
            },
            {
                path: 'businesstype/view/:businesstypeid',
                name: 'view-businesstype',
                component: () => import('@/views/hyzadmin/masterdata/businesstypeView.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'businesstype/edit/:businesstypeid',
                name: 'edit-businesstype',
                component: () => import('@/views/hyzadmin/masterdata/businesstypeEdit.vue'),
                meta: { has_perm: hyz_perm.c_m_md },
            },
            {
                path: 'pendingapprovals',
                name: 'pendingapprovals',
                component: () => import('@/views/hyzadmin/approvals/pendingApprovals.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path: 'pendingapprovals-view/:itemId/:itemType',
                name: 'pendingapprovals-view',
                component: () => import('@/views/hyzadmin/approvals/pendingApprovalView.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path: 'update-requests',
                name: 'update-requests',
                component: () => import('@/views/hyzadmin/approvals/updateRequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path: 'update-requests-details/:itemId/:itemType',
                name: 'update-requests-details',
                component: () => import('@/views/hyzadmin/approvals/updateRequestView.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'representativ-requests',
                name: 'representativ-requests',
                component: () => import('@/views/hyzadmin/approvals/busnessRepRequestes.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'rep-requested-bsn-view/:bsnId',
                name: 'rep-requested-bsn-view',
                component: () => import('@/views/hyzadmin/approvals/repRequestedBusnsView.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'rejected-requests',
                name: 'rejected-requests',
                component: () => import('@/views/hyzadmin/approvals/rejectedRequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'rejected-requests-view/:itemId/:itemType',
                name: 'rejected-requests-view',
                component: () => import('@/views/hyzadmin/approvals/rejectedRequestsView.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'image-requests',
                name: 'image-requests',
                component: () => import('@/views/hyzadmin/approvals/listImageRequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'image-requests-view/:busnId',
                name: 'image-requests-view',
                component: () => import('@/views/hyzadmin/approvals/viewImageRequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'brochure-requests',
                name: 'brochure-requests',
                component: () => import('@/views/hyzadmin/approvals/listBrochureRequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'brochure-requests-view/:busnId',
                name: 'brochure-requests-view',
                component: () => import('@/views/hyzadmin/approvals/viewBrochureRequest.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'video-requests',
                name: 'video-requests',
                component: () => import('@/views/hyzadmin/approvals/listVideoRequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'business-logo-requests',
                name: 'business-logo-requests',
                component: () => import('@/views/hyzadmin/approvals/businessLogoRequest.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'bulk-message-requests',
                name: 'bulk-message-requests',
                component: () => import('@/views/hyzadmin/approvals/bulkMessageRequest.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'bulk-message-cstmr-list/:busn_id',
                name: 'bulk-message-cstmr-list',
                component: () => import('@/views/hyzadmin/approvals/bulkMessageCstmrList.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'sw-service-purchase-request',
                name: 'sw-service-purchase-request',
                component: () => import('@/views/hyzadmin/outsource/allSoftwareServicerequests.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'sw-service-purchase-request-details/:rqstId',
                name: 'sw-service-purchase-request-details',
                component: () => import('@/views/hyzadmin/outsource/detailsPageForPurchase.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'sw-service-order-history',
                name: 'sw-service-order-history',
                component: () => import('@/views/hyzadmin/outsource/previousOrdersList.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'outsource-providers',
                name: 'outsource-providers',
                component: () => import('@/views/hyzadmin/outsource/outsourcePrvidersList.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path:'providers-clients/:providerId',
                name: 'providers-clients',
                component: () => import('@/views/hyzadmin/outsource/providersClientList.vue'),
                meta: { has_perm: hyz_perm.c_m_approval },
            },
            {
                path: 'listallbusiness',
                name: 'listallbusiness',
                component: () => import('@/views/hyzadmin/business/businessList.vue'),
                meta: { has_perm: hyz_perm.c_v_busn },
            },
            {
                path: 'business-view/:busn_id',
                name: 'business-view',
                component: () => import('@/views/hyzadmin/business/businessView.vue'),
                meta: { has_perm: hyz_perm.c_v_busn },
            },
            {
                path: 'addnewbusiness',
                name: 'addnewbusiness',
                component: () => import('@/views/hyzadmin/business/addNewBusiness.vue'),
                meta: { has_perm: hyz_perm.c_a_busn },
            },
            {
                path: 'edit-business/:busn_id',
                name: 'edit-business',
                component: () => import('@/views/hyzadmin/business/editBusiness.vue'),
                meta: { has_perm: hyz_perm.c_e_busn },
            },
            {
                path: 'listcustomers',
                name: 'listcustomers',
                component: () => import('@/views/hyzadmin/customer/customersList.vue'),
                meta: { has_perm: hyz_perm.c_v_custmr },
            },
            {
                path: 'productbrandlist',
                name: 'productbrandlist',
                component: () => import('@/views/hyzadmin/masterdata/productBrandList.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'productbrandadd',
                name: 'productbrandadd',
                component: () => import('@/views/hyzadmin/masterdata/productBrandAdd.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'productbrandedit/:brandId',
                name: 'productbrandedit',
                component: () => import('@/views/hyzadmin/masterdata/productBrandEdit.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'productbrandview/:brandId',
                name: 'productbrandview',
                component: () => import('@/views/hyzadmin/masterdata/productBrandView.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'servicesofferedlist',
                name: 'servicesofferedlist',
                component: () => import('@/views/hyzadmin/masterdata/servicesOfferedList.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'servicesofferedadd',
                name: 'servicesofferedadd',
                component: () => import('@/views/hyzadmin/masterdata/servicesOfferedAdd.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'servicesofferededit/:srviceOfferId',
                name: 'servicesofferededit',
                component: () => import('@/views/hyzadmin/masterdata/serviceOfferEdit.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'servicesofferedview/:srviceOfferId',
                name: 'servicesofferedview',
                component: () => import('@/views/hyzadmin/masterdata/serviceOfferView.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'amnetiesandfacilities',
                name: 'amnetiesandfacilities',
                component: () => import('@/views/hyzadmin/masterdata/serviceAmnetiesAndFacilitiesList.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'amnetiesandfacilityadd',
                name: 'amnetiesandfacilityadd',
                component: () => import('@/views/hyzadmin/masterdata/serviceAmnetiesAndFacilitiesAdd.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'amnetiesandfacilityedit/:amnetyId',
                name: 'amnetiesandfacilityedit',
                component: () => import('@/views/hyzadmin/masterdata/serviceAmnetiesAndFacilitiesEdit.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'amnetiesandfacilityview/:amnetyId',
                name: 'amnetiesandfacilityview',
                component: () => import('@/views/hyzadmin/masterdata/serviceAmnetiesAndFacilitiesView.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'external-sw-services',
                name: 'external-sw-services',
                component: () => import('@/views/hyzadmin/outsource/externalSwAndServices.vue'),
                meta: { has_perm: true },
            },
            {
                path: 'viewcustomer/:cus_id',
                name: 'viewcustomer',
                component: () => import('@/views/hyzadmin/customer/customerView.vue'),
                meta: { has_perm: hyz_perm.c_v_custmr },
            },
            {
                path: 'viewcustomer-business/:busnId',
                name: 'viewcustomer-business',
                component: () => import('@/views/hyzadmin/customer/customerBusinessView.vue'),
                meta: { has_perm: hyz_perm.c_v_custmr },
            },
            {
                path: 'customer-add',
                name: 'customer-add',
                component: () => import('@/views/hyzadmin/customer/addNewCustomer.vue'),
                meta: { has_perm: hyz_perm.c_a_custmr },
            },
            {
                path: 'customer-edit/:cus_id',
                name: 'customer-edit',
                component: () => import('@/views/hyzadmin/customer/editCustomer.vue'),
                meta: { has_perm: hyz_perm.c_e_custmr },
            },
            
            {
                path:'allocated-busns',
                name: 'allocated-busns',
                component: () => import('@/views/hyzadmin/busnrep/localBusinesses.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'activated-busns',
                name: 'activated-busns',
                component: () => import('@/views/hyzadmin/busnrep/activationBusinesses.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'allocated-business-view/:bsnId',
                name: 'allocated-business-view',
                component: () => import('@/views/hyzadmin/busnrep/allocatedBsnView.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'activated-business-view/:bsnId',
                name: 'activated-business-view',
                component: () => import('@/views/hyzadmin/busnrep/activatedBusinessView.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'add-new-business',
                name: 'add-new-business',
                component: () => import('@/views/hyzadmin/busnrep/addNewBusiness.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'products-lists',
                name: 'products-list',
                component: () => import('@/views/hyzadmin/product/productsList.vue'),
                meta: { has_perm: hyz_perm.c_v_busn },
            },
            {
                path:'products-view/:prdctId',
                name: 'products-view',
                component: () => import('@/views/hyzadmin/product/productView.vue'),
                meta: { has_perm: hyz_perm.c_v_busn },
            },
            {
                path:'service-list',
                name: 'service-list',
                component: () => import('@/views/hyzadmin/service/servicesList.vue'),
                meta: { has_perm: hyz_perm.c_v_busn },
            },
            {
                path:'service-view/:serviceId',
                name: 'service-view',
                component: () => import('@/views/hyzadmin/service/serviceView.vue'),
                meta: { has_perm: hyz_perm.c_v_busn },
            },
            {
                path:'local-partner-sbscription-details',
                name: 'local-partner-sbscription-details',
                component: () => import('@/views/hyzadmin/busnrep/subscriptionDetail.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'add-banner',
                name: 'add-banner',
                component: () => import('@/views/hyzadmin/advertisement/addBanner.vue'),
                meta: { has_perm: hyz_perm.c_m_h_advertisement },
            },
            {
                path:'list-banners',
                name: 'list-banners',
                component: () => import('@/views/hyzadmin/advertisement/listBanners.vue'),
                meta: { has_perm: hyz_perm.c_m_h_advertisement },
            },
            {
                path:'list-feedback-suggestion',
                name: 'list-feedback-suggestion',
                component: () => import('@/views/hyzadmin/customercare/listSuggestionsAndFeedbacks.vue'),
                meta: { has_perm: hyz_perm.c_mang_public_action },
            },
            {
                path:'manage-contact-us',
                name: 'manage-contact-us',
                component: () => import('@/views/hyzadmin/customercare/manageContactUs.vue'),
                meta: { has_perm: hyz_perm.c_mang_public_action },
            },
            {
                path:'manage-dsa-contacts',
                name: 'manage-dsa-contacts',
                component: () => import('@/views/hyzadmin/customercare/manageDSAContactForm.vue'),
                meta: { has_perm: hyz_perm.c_mang_public_action },
            },
            {
                path:'all-notifications',
                name: 'all-notifications',
                component: () => import('@/views/hyzadmin/Reminders/notificationList.vue'),
                meta: { has_perm: true },
            },
            {
                path:'available-softwares-services',
                name: 'available-softwares-services',
                component: () => import('@/views/hyzadmin/busnrep/software And Services/availableSoftwares.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'softwares-services-request',
                name: 'softwares-services-request',
                component: () => import('@/views/hyzadmin/busnrep/software And Services/sendNewRequest.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            {
                path:'softwares-services-request-list',
                name: 'softwares-services-request-list',
                component: () => import('@/views/hyzadmin/busnrep/software And Services/swServiceOrderList.vue'),
                meta: { has_perm: hyz_perm.c_v_loc_bus },
            },
            

        ],
    },

    // HYZ without auth
    {
        path: '/fingertipypadm',
        component: () => import('@/layouts/HyzLoginLayout.vue'),

        children: [
            {
                path: 'login',
                name: 'hyzlogin',
                component: () => import('@/views/hyzadmin/auth/LoginPage.vue'),
                meta: { has_perm: true, },
            },

        ],
    },
    {
        path: '/info',
        // component: () => import('@/layouts/HyzLoginLayout.vue'),

        children: [
            {
                path: 'help-support',
                name: 'help-support',
                component: () => import('@/views/footerLinks/helpAndSupport.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'privacy-policy',
                name: 'privacy-policy',
                component: () => import('@/views/footerLinks/privacyPolicy.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'contactus',
                name: 'contactus',
                component: () => import('@/views/footerLinks/contactUs.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'terms-condition',
                name: 'terms-condition',
                component: () => import('@/views/footerLinks/termsAndCondition.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'DSA-Contact-Form',
                name: 'DSA-Contact-Form',
                component: () => import('@/views/footerLinks/DSAContactForm.vue'),
                meta: { has_perm: true, },
            },
            {
                path: 'DSA-Contact-landing',
                name: 'DSA-Contact-landing',
                component: () => import('@/views/footerLinks/DSAContactLanding.vue'),
                meta: { has_perm: true, },
            },

        ],
    },
    //For external redirections  
    {
        path: '/externallinks',
        name: 'external-links',
        component: () => import('@/views/ExternalLink.vue'),
        meta: { has_perm: true, external: true, requiresAuthHyz: false, requiresAuth: false } // Optional: Add a meta field to mark it as an external link
    },

    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: { has_perm: true, requiresAuthHyz: false, requiresAuth: false },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];

    //Customer/Business 
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = localStorage.getItem('nrsllrcust');
    
    //HYZ 
    const authRequiredHyz = to.matched.some(record => record.meta.requiresAuthHyz)
    const loggedInHyz = localStorage.getItem('nrsllradm');

    const hasPermission = to.matched.some(record => record.meta.has_perm)

    if (authRequired && !loggedIn) {
        const path = ''
        next('/' + path);
    } 
    else if (authRequiredHyz && !loggedInHyz) {
        const path_hyz = ''
        next('fingertipypadm/login' + path_hyz);
    }
    else if (!hasPermission) {
        return next('/not-found');
    } else {
        next()
    }
})

export default router